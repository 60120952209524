import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from 'grommet';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { lastDayOfMonth, format, isThisMonth, max } from 'date-fns';
import { de } from 'date-fns/locale';
import { OfferWithImage, OfferVariant } from '../models/offer';
import { black, red, redLight } from '../utils/colors';
import offersCollection from '../data/offers-collection';

const Offers: React.FC = () => {
  const {
    allFile: { edges: images },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/^offers//" } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData(height: 200, layout: FIXED)
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {offersCollection
        // only show up-to-date offers
        .filter(
          ({ month, year, moreMonths }) =>
            isThisMonth(new Date(year, month)) ||
            moreMonths?.some(({ month: anotherMonth, year: anotherYear }) =>
              isThisMonth(new Date(anotherYear, anotherMonth)),
            ),
        )
        .map(({ month, year, moreMonths = [], offers, below }) => {
          const lastMonth = max([
            new Date(year, month),
            ...moreMonths.map(
              ({ month: anotherMonth, year: anotherYear }) =>
                new Date(anotherYear, anotherMonth),
            ),
          ]);
          const lastDateOfMonth = lastDayOfMonth(lastMonth);
          const monthLabel = format(lastDateOfMonth, 'LLLL', { locale: de });
          const dueDateLabel = format(lastDateOfMonth, 'dd.MM.yyyy');

          // connect offers with their image files
          const offersWithImage = offers.map((offer) => {
            const image = (
              images.find(
                ({ node }: any) =>
                  node.relativePath === `offers/${offer.imgFile}`,
              ) || {}
            ).node;
            return {
              ...offer,
              image,
            } as OfferWithImage;
          });
          return (
            <div key={dueDateLabel}>
              <h2>Angebote im {monthLabel}</h2>
              <Box direction="row" wrap justify="center">
                {offersWithImage.map((offer) => (
                  <OfferBox offer={offer} key={offer.title} />
                ))}
              </Box>
              {below}
              <p>
                Zu Risiken oder Nebenwirkungen lesen Sie die Packungsbeilage und
                fragen Sie Ihre Ärztin, Ihren Arzt oder in Ihrer Apotheke.
                <br />
                Das Team der Hirsch-Apotheke berät Sie gern!
              </p>
              <p>
                <small>Angebote gültig bis {dueDateLabel}</small>
              </p>
            </div>
          );
        })}
      <h4>Fragen Sie nach unserem Rezept- und Lieferservice!</h4>
    </>
  );
};

export default Offers;

interface OfferProps {
  offer: OfferWithImage;
}

const OfferBox = ({ offer }: OfferProps) => (
  <OfferContainer>
    <StyledGatsbyImage
      image={offer.image.childImageSharp.gatsbyImageData}
      objectFit="contain"
      alt={offer.image.name}
    />
    <OfferPricingBox>
      {([offer, ...(offer.variants ?? [])] as Partial<OfferVariant>[]).map(
        ({ name, offerPrice, originalPrice, offerPriceFrom }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <RowWithEmsp key={i}>
            {name && <OfferVariantName>{name}</OfferVariantName>}
            {offerPrice && (
              <OfferPrice price={offerPrice} priceFrom={offerPriceFrom} />
            )}
            {originalPrice && <OriginalPrice price={originalPrice} />}
          </RowWithEmsp>
        ),
      )}
    </OfferPricingBox>

    <OfferTitle>{offer.title}</OfferTitle>
    <p>
      <small>{offer.description}</small>
    </p>
  </OfferContainer>
);

const StyledGatsbyImage = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 100%;
`;

const OfferTitle = styled.h4`
  margin-bottom: 10px;
`;

const OfferPricingBox = styled.div`
  margin-bottom: 30px;
`;

const euroFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

interface OfferPriceProps {
  price: number;
  priceFrom?: boolean;
}

const OfferVariantName = styled.span`
  color: ${black};
`;

const OfferPrice = ({ price, priceFrom }: OfferPriceProps) => (
  <OfferPriceSpan>
    <small>{priceFrom ? 'ab' : 'nur'}</small>{' '}
    <strong>{euroFormatter.format(price)}</strong>
  </OfferPriceSpan>
);

const OfferPriceSpan = styled.span`
  color: ${red};
  font-size: 1.1em;
`;

interface OriginalPriceProps {
  price: number;
}

const OriginalPrice = ({ price }: OriginalPriceProps) => (
  <small>
    <s>statt {euroFormatter.format(price)}</s>
  </small>
);

interface RowWithEmspProps {
  children: React.ReactNodeArray;
}

const RowWithEmsp = ({ children }: RowWithEmspProps) => {
  return (
    <div>
      {children
        .filter((child) => !!child)
        .reduce(
          (result: React.ReactNodeArray, child, i) => [
            ...result,
            i > 0 ? (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`${i}-emsp`}>&emsp;</React.Fragment>
            ) : null,
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${i}-child`}>{child}</React.Fragment>,
          ],
          [],
        )}
    </div>
  );
};

const OfferContainer = styled.div`
  overflow: hidden;
  width: 315px;
  max-width: 100%;
  padding: 5px;
  border: 1px solid ${redLight};
  margin: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
