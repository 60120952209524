import Month from '../models/months';
import MonthlyOffer from '../models/monthly-offer';

const offersCollection: MonthlyOffer[] = [
  // ##### TEMPLATE
  // {
  //   month: Month.XX,
  //   year: 2025,
  //   offers: [
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //     {
  //       title: '',
  //       description: '',
  //       imgFile: '.png',
  //       offerPrice: 0.0,
  //     },
  //   ],
  // },
  // #####
  {
    month: Month.February,
    year: 2025,
    offers: [
      {
        title: 'Ipalat',
        description: '20 Lutschpastillen',
        imgFile: 'ipalat_zuckerfrei.png',
        offerPrice: 5.99,
      },
      {
        title: 'ACC akut 600 mg',
        description: 'Hustenlöser, 20 Brausetabletten',
        imgFile: 'acc-akut-600-l.png',
        offerPrice: 9.99,
      },
      {
        title: 'Medikationscheck',
        description:
          'Optimieren Sie Ihre Medikation für mehr Sicherheit und Wirkung. Ganz individuell auf Sie abgestimmt!',
        imgFile: 'pexels-pixabay-161688.jpg',
      },
    ],
  },
];

export default offersCollection;
